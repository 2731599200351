import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

const USER_ID_KEY = 'AuthUserId';
const TOKEN_KEY = 'AuthToken';
const USER_NAME_KEY = 'AuthUserName';
const AUTHORITIES_KEY = 'AuthAuthorities';
const PERMISSIONS_KEY = 'AuthPermissions';
const NOMBRE_COMPLETO_KEY = 'NombreCompleto';
const ROL_PRINICPAL_KEY = 'RolPrincipal';
const FECHA_ULTIMO_INGRESO_KEY = 'fechaUltSesion';
const HORA_ULTIMO_INGRESO_KEY = 'horaUltSesion';
const NRO_IP_KEY = 'nroIp';

@Injectable({
  providedIn: "root",
})
export class TokenService {
  roles: Array<string> = [];
  permisos: Array<string> = [];

  private userLoggedIn = new Subject<boolean>();

  constructor() {
    this.userLoggedIn.next(false);
  }

  public setUserId(userId: number): void {
    if (userId) {
      window.sessionStorage.removeItem(USER_ID_KEY);
      window.sessionStorage.setItem(USER_ID_KEY, userId.toString());
    }
  }

  public getUserId(): number {
    return +sessionStorage.getItem(USER_ID_KEY);
  }
  public setToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public setUserName(userName: string): void {
    window.sessionStorage.removeItem(USER_NAME_KEY);
    window.sessionStorage.setItem(USER_NAME_KEY, userName);
  }

  public getUserName(): string {
    return sessionStorage.getItem(USER_NAME_KEY);
  }

  public setAuthorities(authorities: string[]): void {
    window.sessionStorage.removeItem(AUTHORITIES_KEY);
    window.sessionStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[] {
    this.roles = [];
    if (sessionStorage.getItem(AUTHORITIES_KEY)) {
      JSON.parse(sessionStorage.getItem(AUTHORITIES_KEY)).forEach(
        (authority) => {
          this.roles.push(authority.authority);
        }
      );
    }
    return this.roles;
  }

  public setPermissions(permissions: string[]): void {
    window.sessionStorage.removeItem(PERMISSIONS_KEY);
    window.sessionStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
  }

  public getPermissions(): string[] {
    this.permisos = [];
    if (sessionStorage.getItem(PERMISSIONS_KEY)) {
      JSON.parse(sessionStorage.getItem(PERMISSIONS_KEY)).forEach(
        (permission) => {
          this.permisos.push(permission);
        }
      );
    }
    return this.permisos;
  }

  public setNombreCompleto(nombreCompleto: string): void {
    window.sessionStorage.removeItem(NOMBRE_COMPLETO_KEY);
    window.sessionStorage.setItem(NOMBRE_COMPLETO_KEY, nombreCompleto);
  }

  public getNombreCompleto(): string {
    return sessionStorage.getItem(NOMBRE_COMPLETO_KEY);
  }

  public setRolPrincipal(rolPrincipal: string): void {
    window.sessionStorage.removeItem(ROL_PRINICPAL_KEY);
    window.sessionStorage.setItem(ROL_PRINICPAL_KEY, rolPrincipal);
  }

  public getRolPrincipal(): string {
    return sessionStorage.getItem(ROL_PRINICPAL_KEY);
  }

  public setFechaUltimoIngreso(fechaUltIngreso: string): void {
    window.sessionStorage.removeItem(FECHA_ULTIMO_INGRESO_KEY);
    window.sessionStorage.setItem(FECHA_ULTIMO_INGRESO_KEY, fechaUltIngreso);
  }

  public getFechaUltimoIngreso(): string {
    return sessionStorage.getItem(FECHA_ULTIMO_INGRESO_KEY);
  }

  public setHoraUltimoIngreso(HoraUltIngreso: string): void {
    window.sessionStorage.removeItem(HORA_ULTIMO_INGRESO_KEY);
    window.sessionStorage.setItem(HORA_ULTIMO_INGRESO_KEY, HoraUltIngreso);
  }

  public getHoraUltimoIngreso(): string {
    return sessionStorage.getItem(HORA_ULTIMO_INGRESO_KEY);
  }

  public setNroIp(nroIp: string): void {
    window.sessionStorage.removeItem(NRO_IP_KEY);
    window.sessionStorage.setItem(NRO_IP_KEY, nroIp);
  }

  public getNroIp(): string {
    return sessionStorage.getItem(NRO_IP_KEY);
  }

  public logOut(): void {
    window.sessionStorage.clear();
    this.setUserLoggedIn(false);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
}
