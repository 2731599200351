import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AutenticacionService } from '../services/autenticacion.service';
import { TokenService } from '../services/token.service';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    ab?: string;
    type: string;
    icontype?: string;
    collapse?: string;
    children?: RouteInfo[];
    expectedRol?: any;
    expectedPermission?: any;
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    children?: ChildrenItems[];
    expectedRol?: any;
    expectedPermission?: any;

}

// Menu Items
export const ROUTES: RouteInfo[] = [{
        path: '/dashboard',
        title: 'Dashboard',
        type: 'sub',
        icontype: 'dashboard',
        collapse: 'dashboard',
        children: [
          {
            path : 'graficos',
            title: 'Gráficos',
            type: 'sub',
            icontype: 'timeline',
            collapse: 'graficos',
            children: [
                        {path: 'inicio', title: 'Inicio', type: 'subC', ab: 'DI',
                        expectedRol: ['ROLE_ENCARGADO_FONDO','ROLE_SFG_GERENTE_GENERAL','ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_CONTADOR_FOGAPY'],
                        expectedPermission: ['P935']
                        },
                        {path: 'resumen', title: 'Resumen', type: 'subC', ab: 'DR',
                            expectedRol: ['ROLE_ENCARGADO_FONDO','ROLE_SFG_GERENTE_GENERAL','ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_CONTADOR_FOGAPY'],
                            expectedPermission: ['P936']
                        },
                        {path: 'ifi', title: 'Ifi', type: 'subC', ab: 'DR',
                            expectedRol: ['ROLE_ENCARGADO_FONDO','ROLE_SFG_GERENTE_GENERAL','ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_CONTADOR_FOGAPY'],
                            expectedPermission: ['P937']
                        }
            ]
          },

          {
            path : 'tablas',
            title: 'Tablas',
            type: 'sub',
            icontype: 'grid_on',
            collapse: 'tablas',
            children: [
                    {path: 'garantia-acumulada-detallada', title: 'Garantía Acum. Detallada', type: 'subC', ab: 'GAD',
                      expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_GERENTE_GENERAL'],
                      expectedPermission: ['P938']
                    },
                    {path: 'garantia-acumulada-moneda', title: 'Garantía Acum. Global por Moneda', type: 'subC', ab: 'GAGM',
                      expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_GERENTE_GENERAL'],
                      expectedPermission: ['P939']
                    },
                    {path: 'garantia-acumulada-global', title: 'Garantía Acum. Global', type: 'subC', ab: 'GAG',
                      expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_GERENTE_GENERAL'],
                      expectedPermission: ['P940']
                    },  
            ]
          }

          
        ],
        expectedRol: ['ROLE_ENCARGADO_FONDO','ROLE_SFG_GERENTE_GENERAL','ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_CONTADOR_FOGAPY'],
        expectedPermission: ['P965']

    }, {
        path: '/contabilidad',
        title: 'Contabilidad',
        type: 'sub',
        icontype: 'timeline',
        collapse: 'contabilidad',
        children: [
            {path: 'plan-de-cuenta', title: 'Plan de Cuentas', type: 'subC', ab: 'PC',
              expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
              expectedPermission: ['P902']
            },
            {path: 'asiento-contable', title: 'Asientos Contables', type: 'subC', ab: 'AC',
              expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
              expectedPermission: ['P911']
            },
            {path: 'aprobacion-masiva-asiento', title: 'Aprobación Masiva de Asientos', type: 'subC', ab: 'AMA',
            expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
            expectedPermission: ['P941']
            },
            {path: 'movimiento-cuenta', title: 'Movimientos de Cuenta', type: 'subC', ab: 'MC',
              expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD', 'ROLE_ENCARGADO_FONDO'],
              expectedPermission: ['P942']
            },

            {path: 'comprobantes-recibidos', title: 'Comprobantes Recibidos', type: 'subC', ab: 'CR',
              expectedRol: ['ROLE_SFG_CONT_INF', 'ROLE_SFG_INF_ADM'],
              expectedPermission: []
            },
            {path: 'cierre-mes', title: 'Cierre de Mes', type: 'subC', ab: 'CM',
              expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY' ],
              expectedPermission: ['P943']
            },
            {path: 'importar-asientos', title: 'Importar Asientos', type: 'subC', ab: 'IA',
            expectedRol: ['ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_CONTADOR_FOGAPY' ],
            expectedPermission: ['P944']
            },
            {path: 'importar-masiva-asientos', title: 'Importar Asientos Masivo', type: 'subC', ab: 'IAM',
            expectedRol: ['ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_CONTADOR_FOGAPY' ],
            expectedPermission: ['P945']
            },
            {path: 'asiento-no-registrado', title: 'Asientos no Registrados', type: 'subC', ab: 'ANR',
            expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
            expectedPermission: ['P963']
          }


        ],
         expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO'],
         expectedPermission:['P966']
      },
      {
        path : '/reportes',
        title: 'Reportes',
        type: 'sub',
        icontype: 'account_balance',
        collapse: 'reportes',
        children: [
        
          {
            path : 'reportes-contabilidad',
            title: 'Contabilidad',
            type: 'sub',
            icontype: 'timeline',
            collapse: 'reportes-contabilidad',
            children: [
              {path: 'balance-consolidado', title: 'Balance Consolidado', type: 'subC', ab: 'BC',
                  expectedRol:  ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO'],
                  expectedPermission: ['P946']
                },
                {path: 'balance-consolidado-mes', title: 'Balance Consolidado Mes', type: 'subC', ab: 'BC',
                  expectedRol:  ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO'],
                  expectedPermission: ['P947']
                },
                {path: 'libro-diario', title: 'Libro Diario', type: 'subC', ab: 'LD',
                  expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
                  expectedPermission: ['P948']
                },
                {path: 'libro-mayor', title: 'Libro Mayor', type: 'subC', ab: 'LM',
                  expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
                  expectedPermission: ['P949']
                },
                /*
                {path: 'garantias-emitidas', title: 'Garantías Emitidas', type: 'subC', ab: 'GE',
                  expectedRol: ['ROLE_ENCARGADO_FONDO']
                },
                */
               
                {path: 'prevision-mes-detallado', title: 'Previsiones por Mes Detallado', type: 'subC', ab: 'DP',
                  expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY'],
                  expectedPermission: ['P950']
                }
            ]
          },
          {
            path : 'reportes-fondo-garantia',
            title: 'Fondo de Garantías',
            type: 'sub',
            icontype: 'check_circle_outline',
            collapse: 'reportes-fondo-garantia',
            children: [
                {path: 'beneficiario-garantia-unica', title: 'Beneficiarios con Garantía Única', type: 'subC', ab: 'BGU',
                  expectedRol: ['ROLE_ENCARGADO_FONDO', 'ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD', 'SFG_GERENTE_GENERAL','ROLE_COMERCIAL_FONDO'],
                  expectedPermission: ['P951']
                },
                {path: 'comparativo-comision-cobrada', title: 'Comparativo de Comisiones Cobradas', type: 'subC', ab: 'CCC',
                expectedRol: ['ROLE_ENCARGADO_FONDO', 'ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD', 'SFG_GERENTE_GENERAL','ROLE_COMERCIAL_FONDO'],
                expectedPermission: ['P959']
                },
                {path: 'total-comision-cobrada', title: 'Total de Comisiones Cobradas', type: 'subC', ab: 'TCC',
                expectedRol: ['ROLE_ENCARGADO_FONDO', 'ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD', 'SFG_GERENTE_GENERAL','ROLE_COMERCIAL_FONDO'],
                expectedPermission: ['P960']
                
              },
              {path: 'movimientos_cupos', title: 'Movimientos Cupos', type: 'subC', ab: 'MC',
                expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
                expectedPermission: ['P952']
              },
              {path: 'siniestros', title: 'Siniestros', type: 'subC', ab: 'SIN',
              expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO','ROLE_COMERCIAL_FONDO','SFG_GERENTE_GENERAL'],
              expectedPermission: ['P961']       
              },
              {path: 'garantia-renegociada', title: 'Garantias Renegociadas', type: 'subC', ab: 'GR',
              expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
              expectedPermission: ['P962']
              },
              {path: 'garantia-proceso-pago', title: 'Garantias en Proceso de Pago', type: 'subC', ab: 'GPP',
              expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD', 'ROLE_ENCARGADO_FONDO', 'ROLE_ANALISTA_LEGAL','ROLE_COMERCIAL_FONDO'],
              expectedPermission: ['P953']
              }
            ]
          }         

        ],
        expectedRol:  ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO','ROLE_ANALISTA_LEGAL', 'SFG_GERENTE_GENERAL','ROLE_COMERCIAL_FONDO'],
        expectedPermission: ['P967']
      },
      {
        path: '/garantia',
        title: 'Gestión de Garantías',
        type: 'sub',
        icontype: 'memory',
        collapse: 'garantia',
        children: [
          {path: 'seguimiento-garantia', title: 'Seguimiento de Garantías', type: 'subC', ab: 'SG',
                expectedRol: [ 'ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO','ROLE_COMERCIAL_FONDO','ROLE_ANALISTA_LEGAL'],
                expectedPermission: ['P954']
          }, 
          {path: 'seguimiento-pago-garantia', title: 'Seguimiento Pago de Garantías', type: 'subC', ab: 'SPG',
          expectedRol: ['ROLE_AREA_LEGAL','ROLE_GERENCIA_RIESGOS','ROLE_FOGAPY','ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO','ROLE_COMERCIAL_FONDO','ROLE_ANALISTA_LEGAL'],
          expectedPermission: ['P955']
          }, 
          {path: 'garantia-confirmada', title: 'Garantías Confirmadas', type: 'subC', ab: 'GC',
          expectedRol: ['ROLE_ENCARGADO_FONDO','ROLE_COMERCIAL_FONDO'],
          expectedPermission: ['P956']
          }, 
          {path: 'tablero-control', title: 'Tablero de procesos', type: 'subC', ab: 'TAC',
          expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
          expectedPermission: ['P957']
          },
          {path: 'configuracion-comisiones', title: 'Configuración de comisiones', type: 'subC', ab: 'CC',
          expectedRol: ['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_COMERCIAL_FONDO'],
          expectedPermission: ['P958']
          }
        ],
        expectedRol: ['ROLE_AREA_LEGAL','ROLE_GERENCIA_RIESGOS','ROLE_FOGAPY','ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO','ROLE_COMERCIAL_FONDO','ROLE_ANALISTA_LEGAL'],
        expectedPermission: ['P968']
      },
    {
        path: '/mantenimiento',
        title: 'Mantenimiento',
        type: 'sub',
        icontype: 'reorder',
        collapse: 'mantenimiento',
        children: [
              {path: 'tipo-estado', title: 'Tipo Estado', type: 'subC', ab: 'TE',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P912']
              },
              {path: 'tipo-estado/agregar', title: 'Agregar Tipo Estado', type: 'subN', ab: 'ATE',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P918']
              },
              {path: 'tipo-estado/editar', title: 'Editar Tipo Estado', type: 'subN', ab: 'ETE',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P919']
              },
              {path: 'estado', title: 'Estado', type: 'subC', ab: 'E',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P913']
              },
              {path: 'estado/agregar', title: 'Agregar Estado', type: 'subN', ab: 'AE',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P920']
              },
              {path: 'estado/editar', title: 'Editar Estado', type: 'subN', ab: 'EE',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P921']
              },
              {path: 'categoria-tipo', title: 'Categoria Tipo', type: 'subC', ab: 'CT',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P914']
              },
              {path: 'categoria-tipo/agregar', title: 'Agregar Categoria Tipo', type: 'subN', ab: 'ACT',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P922']
              },
              {path: 'categoria-tipo/editar', title: 'Editar Categoria Tipo', type: 'subN', ab: 'ECT',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P923']
              },
              {path: 'tipo', title: 'Tipo', type: 'subC', ab: 'T',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P915']
              },
              {path: 'tipo/agregar', title: 'Agregar Tipo', type: 'subN', ab: 'AT',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P924']
              },
              {path: 'tipo/editar', title: 'Editar Tipo', type: 'subN', ab: 'ET',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P925']
              },
              {path: 'clasificacion-beneficiario', title: 'Clasific. Beneficiario', type: 'subC', ab: 'CB',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P916']
              },
              {path: 'clasificacion-beneficiario/agregar', title: 'Agregar Clasificacion', type: 'subN', ab: 'ACB',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P926']
              },
              {path: 'clasificacion-beneficiario/editar', title: 'Editar Clasificacion', type: 'subN', ab: 'ECB',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P927']
              },
              {path: 'cotizacion', title: 'Cotización', type: 'subC', ab: 'C',
                expectedRol: ['ROLE_SFG_ADM_TI','ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
                expectedPermission: ['P917']
              },
              {path: 'tipo-comprobante', title: 'Tipo Comprobante', type: 'subC', ab: 'TC',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: []
              },
              {path: 'proveedor', title: 'Proveedor', type: 'subC', ab: 'P',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: []
              },
              {path: 'ifi-cuenta', title: 'Ifi Cuenta', type: 'subC', ab: 'IC',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P931']
              },
              {path: 'ifi-cuenta/agregar', title: 'Agregar Ifi Cuenta', type: 'subN', ab: 'AIC',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P929']
              },
              {path: 'ifi-cuenta/editar', title: 'Editar Ifi Cuenta', type: 'subN', ab: 'EIC',
                expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P930']
              },
              {path: 'campo-configuracion', title: 'Campo Configuración', type: 'subC', ab: 'CC',
              expectedRol: ['ROLE_SFG_ADM_TI'],
                expectedPermission: ['P934']
              }
        ],
        expectedRol: ['ROLE_SFG_ADM_TI','ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
        expectedPermission: ['P964']
     } 
   

];
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  ps: any;
  usuario: string;
  rolPrincipal: string;
  fUltAcceso: string;
  hUltAcceso: string;
  ip: string;

  constructor(private tokenService: TokenService, private autenticacionService: AutenticacionService) {}

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  mostrar() {
    /* var roles = this.tokenService.getPermissions();
    if (roles.indexOf("P9") >= 0) {
      return true;
    } else {
      return false;
    }*/
    return true;
  }

  // mostrarMenu(menuItem: RouteInfo) {
  //   if (!menuItem.expectedRol) {
  //     return false;
  //   } else {
  //     var roles = this.tokenService.getAuthorities();
  //     return roles.some((item) => menuItem.expectedRol.includes(item));

  //   }
  // }
  mostrarMenu(menuItem: RouteInfo) {
    if (!menuItem.expectedPermission) {
      return false;
    } else {
      var permisos = this.tokenService.getPermissions()
      return permisos.some((item) => menuItem.expectedPermission.includes(item));
    
    }
  }

  // mostrarChildrenMenu(menuItem: ChildrenItems) {
  //   if (!menuItem.expectedRol) {
  //     return false;
  //   } else {
  //     var roles = this.tokenService.getAuthorities();
  //     return roles.some((item) => menuItem.expectedRol.includes(item));
  //   }
  // }



  mostrarChildrenMenu(menuItem: ChildrenItems) {
    if (!menuItem.expectedPermission) {
      return false;
    } else {
      var permisos = this.tokenService.getPermissions();
      return  permisos.some((item) => menuItem.expectedRol.includes(item));

    }
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }
    this.usuario = this.tokenService.getNombreCompleto();
    this.fUltAcceso = this.tokenService.getFechaUltimoIngreso();
    this.hUltAcceso = this.tokenService.getHoraUltimoIngreso();
    this.ip = this.tokenService.getNroIp();
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  salir() {
    this.autenticacionService.logout();
     window.location.reload();
    //  this.router.navigate(['/pages/login']);
  }
}
