import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmationComponent } from './util/confirmation/confirmation.component';
import { ConfirmationInfoComponent } from './util/confirmation/info/confirmation.info.component';
import { PipexmlComponent } from "./pipexml/pipexml.component";
import { getSpainPaginatorIntl } from './spain-paginator-intl';
import {
  NgbdDatepickerI18n,
  CustomDatepickerI18n
} from "./util/inputs/datepicker-i18n/datepicker-i18n";
import { SeparadorMilesPipe } from "./util/pipes/separador-miles.pipe";
import { XmlPipe } from "./util/pipes/xml.pipe";

import { FormatoTemplateComponent } from './administracion/contabilidad/importar-asientos/formato-template/formato-template.component'

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  //MatNativeDateModule,
  MatPaginatorModule,
  MatPaginatorIntl,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MAT_DATE_LOCALE,
  DateAdapter,
} from '@angular/material';
import {
  NgbModule,
  NgbDateParserFormatter,
  NgbDatepickerI18n
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateModule } from "@angular/material-moment-adapter";
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { MY_FORMATS } from './util/my-date-formats';
import { EventEmitterService } from './services/event-emitter.service';
import { interceptorProvider } from './interceptors/interceptor.service';
//dialog
import { BuscadorPlanDeCuentaComponent } from './administracion/contabilidad/plan-de-cuenta/buscador/buscador-plan-de-cuenta.component';
import { RechazarAsientoContableComponent } from './administracion/contabilidad/asiento-contable/cambiar-estado/rechazar-asiento-contable.component';

import { AppRoutes } from './app.routing';
import { NgxPaginationModule } from "ngx-pagination";

import { DatePipe } from '@angular/common';
import { BuscadorIfiComponent } from './administracion/mantenimiento/ifi/buscador/buscador-ifi.component';
import { UserIdleModule } from 'angular-user-idle';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { BuscadorTipoComponent } from './administracion/mantenimiento/tipo/buscador-tipo/buscador-tipo.component';
import { NgxPermissionsAllowStubDirective, NgxPermissionsModule } from 'ngx-permissions';

import { NgxJsonViewerModule } from 'ngx-json-viewer-scrolling';
import { JsonDialogComponent } from './administracion/garantia/tablero-control/json-dialog/json-dialog.component';
import { FalloDialogComponent } from './administracion/contabilidad/importar-asientos/fallo-dialog/fallo-dialog.component';
import { FalloDialogComponent2 } from './administracion/contabilidad/importar-masiva-asientos/fallo-dialog/fallo-dialog.component';
//import { FalloDialogComponent3 } from './shared/fallo-dialog/fallo-dialog.component';
import { TablaFormatoComponent } from './administracion/contabilidad/importar-asientos/tabla-formato/tabla-formato.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    //MatNativeDateModule,
    MomentDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ]
})
export class MaterialModule { }

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: false
    }),
    HttpClientModule,
    MaterialModule,
    //MatNativeDateModule,
    MomentDateModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      preventDuplicates: true
    }),
    UserIdleModule.forRoot({
      idle: 900, 
      timeout: 60,
      ping: 120}),
      NgxPermissionsModule.forRoot(),
      NgxJsonViewerModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ConfirmationComponent,
    ConfirmationInfoComponent,
    PipexmlComponent,
    NgbdDatepickerI18n,
    SeparadorMilesPipe,
    XmlPipe,
    BuscadorPlanDeCuentaComponent,
    RechazarAsientoContableComponent,
    BuscadorIfiComponent,
    ConfirmDialogComponent,
    BuscadorTipoComponent,
    JsonDialogComponent,
    FalloDialogComponent,
    FalloDialogComponent2,
    //FalloDialogComponent3,
    FormatoTemplateComponent,
    TablaFormatoComponent,
    UnauthorizedComponent
  ],
  entryComponents: [ConfirmationComponent, ConfirmationInfoComponent, BuscadorPlanDeCuentaComponent, RechazarAsientoContableComponent,
    BuscadorIfiComponent,ConfirmDialogComponent, BuscadorTipoComponent, JsonDialogComponent, FalloDialogComponent,FalloDialogComponent2, FormatoTemplateComponent],
  providers: [
    { provide: MatPaginatorIntl, useValue: getSpainPaginatorIntl() },
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    EventEmitterService,
    interceptorProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
