//import { FalloDialogService } from './../shared/fallo-dialog/fallo-dialog.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { TokenService } from '../services/token.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router,
        private tokenService: TokenService,
        //private falloDialogService: FalloDialogService
        ) { }

        realRol : string;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
        const expectedRol : String[] = route.data.expectedRol;
        const expectedPermission : String[] = route.data.expectedPermission;
        const roles:String [] = this.tokenService.getAuthorities();
       const permisos:String [] = this.tokenService.getPermissions();
       let booleanReturn : boolean = true;
       this.realRol = 'user';
        if (!this.tokenService.getToken()) {
            
            this.router.navigate(['/pages/login'], { queryParams: { returnUrl: state.url } });
            booleanReturn = false;
        }
        // console.log('roles: ',roles)
        // console.log('permisos: ',permisos)
        // console.log('expectedPermission: ',expectedPermission)
        // console.log(state.url)
        if(!expectedPermission){
            booleanReturn = true;
        }else{
            // let hasRequiredRol = expectedRol.some(rol =>  roles.includes(rol));
            let hasRequiredPermission = expectedPermission.some(permiso =>  permisos.includes(permiso));

            if(hasRequiredPermission){
                booleanReturn = true;
            }
            else{
                this.router.navigate(["/unauthorized"])
                booleanReturn = false;
            }
        }
        return booleanReturn;
     
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        return this.canActivate(route, state);
      }
}